import { computed, ref, watch } from '@vue/composition-api';
import store from '@/store';
import i18n from '@/libs/i18n';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useProfileEdit() {
  const toast = useToast();

  const showSystemNotification = (title, icon, variant) => {
    toast({
      component: ToastificationContent,
      props: {
        title,
        icon,
        variant,
      },
    });
  };

  const profileData = ref(store.state.profile.profile);
  const isAllowAgreement = computed(() => Object.values(store.state.profile.profile.company).filter(val => !val).length === 0);

  const profileToUpdate = ref(null);

  watch(() => store.state.profile.profile, value => {
    if (!profileData.value) {
      profileData.value = value;
    } else {
      Object.assign(profileData.value, value);
    }
  });
  const fetchProfile = async () => {
    try {
      await store.dispatch('profile/fetch');
      profileData.value = store.state.profile.profile;
      profileToUpdate.value = {
        email: profileData.value?.email || '',
        'roles[]': profileData.value?.roles?.filter(item => item?.id === 2 || item?.id === 3) || [],
        'profile[person_name]': profileData.value?.profile?.person_name || '',
        'profile[country_id]': profileData.value?.profile?.country?.id || '',
        password: '',
        password_confirmation: '',
        'company[company_name]': '',
        'company[bank_account]': '',
        'company[bank_name]': '',
        'company[bank_address]': '',
        'company[unp]': '',
        'company[address]': '',
        'company[swift]': '',
        'company[tax_number]': '',
      };
    } catch (err) {
      store.commit('profile/SET_FETCHED_STATUS', false);
    }
  };

  const verifyEmail = async () => {
    try {
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('accountActivationSending', { email: profileData.value?.email }),
          icon: 'CheckCircleIcon',
          variant: 'info',
        },
      });
      await store.dispatch('profile/verify');
      showSystemNotification(
        i18n.t('accountActivationMessage', { email: profileData.value?.email }),
        'EditIcon',
        'success',
      );
    } catch (err) {
      showSystemNotification(i18n.t('errorVerifyingProfile'), 'AlertTriangleIcon', 'danger');
    }
  };

  const updateProfile = async data => {
    await store.dispatch('profile/update', data);
    await fetchProfile();
    showSystemNotification(i18n.t('changesSaved'), 'CheckCircleIcon', 'success');
  };

  const updateAvatar = async updatableData => {
    try {
      await store.dispatch('profile/updateAvatar', updatableData);
      profileData.value = store.state.profile.profile;
    } catch (err) {
      showSystemNotification(i18n.t('errorUpdatingProfile'), 'AlertTriangleIcon', 'danger');
    }
  };

  return {
    profileToUpdate,
    profileData,
    isAllowAgreement,

    verifyEmail,
    fetchProfile,
    updateProfile,
    updateAvatar,
  };
}
